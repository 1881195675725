const bootstrap = require('bootstrap');

function badgeDimensions(size) {
  switch (size) {
    case 'small':
      return [100, 50];
    case 'medium':
      return [150, 65];
    case 'large':
      return [230, 100];
    default:
      return [150, 65];
  }
}

$(document).on('turbolinks:load', function () {
  jQuery(function () {
    // url-checker - start

    $(".collection-hero .scroll-to-link").on("click", function (e) {
      e.preventDefault();
      scrollToEl($($(this).data("scroll-to")));
    });

    if (window.location.hash) {
      const el = $(window.location.hash);
      if (el.length === 0) { return ;}

      scrollToEl($(window.location.hash));
    }

    function scrollToEl(el) {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: el.offset().top - $(".navbar").outerHeight() - 10,
        },
        400
      );
    }

    $('#top-submit-button').on('click', function(e) {
      e.preventDefault();

      $('#edit-collection-form').submit();
    });

    $(".scroll-link ul a").on("click", function (e) {
      e.preventDefault();
      const href = $(this).attr("href");
      scrollToEl($(href))
    });

    let form, input, inputParent, checkBtn, formInfo;
    if ($("#url-check-input").length) {
      form = $("#url-check-form");
      input = $("#url-check-input");
      inputParent = input.parents(".input-group");
      checkBtn = inputParent.find("#url-check-submit");
      formInfo = form.find(".form-info");

      input.on("focus", OnFocusEvent);
      input.on("blur", OnBlurEvent);
      input.on("input", OnInputEvent);
      form.on("submit", function (e) {
        e.preventDefault();
        onSubmitEvent();
      });
      $("body").on("click", ".url-check-clear", function (e) {
        e.preventDefault();
        urlCheckClearForm();
      });
    }

    function OnFocusEvent() {
      inputParent.addClass("focus");
    }

    function OnBlurEvent() {
      inputParent.removeClass("focus");
    }

    function OnInputEvent() {
      if (input.val() === "") {
        checkBtn.attr("disabled", "true");
      } else {
        checkBtn.removeAttr("disabled");
        if (form.hasClass("check-finished")) {
          const inputVal = input.val();
          urlCheckClearForm();
          input.val(inputVal);
        }
      }
    }

    async function onSubmitEvent() {
      const checkBtnHtml = `
      <span>Checking...</span>
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      `;
      checkBtn.addClass("loading");
      form.addClass("checking");
      checkBtn.attr("disabled", "true");
      checkBtn.html(checkBtnHtml);

      const formInfoWaitHtml = `
          <span>One moment while we verify the link</span>
      `;
      formInfo.html(formInfoWaitHtml);

      const urlCheckResult = await urlCheck(input.val());
      checkBtn.removeClass("loading");
      form.removeClass("checking");
      checkBtn.html("Check");
      form.addClass("check-finished");
      if (urlCheckResult) {
        const formInfoValidHtml = `
          <svg class="icon icon-tick">
            <use
            xlink:href="assets/img/collection-report/icons/symbol-sprite.svg#tick"
            ></use>
          </svg>
          <span>Success Message</span>&nbsp;
          <a href="#" class="url-check-clear">Check more links</a>
        `;
        form.addClass("check-valid");
        formInfo.html(formInfoValidHtml);
        formInfo.addClass("form-info-valid");
      } else {
        const formInfoInalidHtml = `
          <svg class="icon icon-error">
            <use
              xlink:href="assets/img/collection-report/icons/symbol-sprite.svg#error"
            ></use>
          </svg>
          <span>Error Message</span>&nbsp;
          <a href="#" class="url-check-clear">Try again</a>
      `;
        form.addClass("check-invalid");
        formInfo.html(formInfoInalidHtml);
        formInfo.addClass("form-info-invalid");
      }
    }

    function urlCheck(url) {
      return new Promise((resolve) => {
        // here goes the logic of the url check
        // the timeOut is removed of course :)
        setTimeout(() => {
          if (url == "correct") resolve(true);
          else resolve(false);
        }, 2000);
      });
    }

    function urlCheckClearForm(url) {
      input.val("");
      form
        .removeClass("check-finished")
        .removeClass("check-valid")
        .removeClass("check-invalid");
      formInfo.html("");
      formInfo.removeClass("form-info-valid").removeClass("form-info-invalid");
    }

    // url-checker - end

    // more btn - start

    if ($("#links-table-more-rows").length) {
      const collapseBtn = $("#links-table-more-btn");
      $("#links-table-more-rows").on("show.bs.collapse", function () {
        const collapsedText = collapseBtn.attr("data-collapsed-text");
        collapseBtn.attr(
          "data-collapsed-text",
          collapseBtn.find("span").text()
        );
        collapseBtn.find("span").text(collapsedText);
      });

      $("#links-table-more-rows").on("hide.bs.collapse", function () {
        const originalText = collapseBtn.attr("data-collapsed-text");
        collapseBtn.attr(
          "data-collapsed-text",
          collapseBtn.find("span").text()
        );
        collapseBtn.find("span").text(originalText);
      });
    }

    // more btn - end

    // tooltips - start

    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    [...tooltipTriggerList].map((tooltipTriggerEl) => {
      const tooltip = new bootstrap.Tooltip(tooltipTriggerEl, {
        html: true,
        trigger: "click",
      });

      $(document).on("click", function (event) {
        if (!$(event.target).closest(tooltipTriggerEl).length) {
          bootstrap.Tooltip.getInstance(tooltipTriggerEl).hide();
        }
      });
    });

    // tooltips - end

    // issues table - start

    if ($(".issues").length) {
      $(window).on("resize", windowResizeEvenet);

      function windowResizeEvenet() {
        updateIssuesCollapseContent();
      }

      function updateIssuesCollapseContent() {
        const width = $(".collection-content__issues-table-wrapper").width();
        $(".issues .issues__content").css("width", width + "px");
        $(".issues__inner-table-wrapper__inner").each(function () {
          $(this).css("height", $(this).find("table").height() + "px");
        });
      }

      $('#collection-alerts').on('click', '.issues__expand-btn', function () {
        const parentWrapper = $(this).parents('.issues__inner-table-wrapper')
        parentWrapper.addClass('hidden');
        scrollToEl(parentWrapper);
      });

      $('#collection-alerts').on('click', '.issues__collapse-btn', function () {
        const parentWrapper = $(this).parents('.issues__inner-table-wrapper')
        parentWrapper.removeClass('hidden');
      });

      updateIssuesCollapseContent();
    }

    // issues table - end

    // copy contract address - start
    function fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      document.execCommand("copy");
      document.body.removeChild(textArea);
    }

    function copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text);
    }

    // share modal copy link - start
    $(".share-modal-copy").on("click", function (e) {
      e.preventDefault();
      copyTextToClipboard($(this).data("copy"));
      const linkTextEl = $(this).find("span");
      linkTextEl.text("Copied!");
      linkTextEl.addClass("copied");
      setTimeout(function () {
        linkTextEl.removeClass("copied");
        linkTextEl.text("Copy link");
      }, 2000);
    });

  });

  // JS for badge selector on edit collection page
  $('#badge-color-selector').on('change', function(e) {
    $('#badge-preview').attr('src', $(this).val())
    const colorSelector = this;
    $('.svg-color').each(function (_, item) {
      $(item).text($(colorSelector).find('option:selected').text().toLowerCase().split(' ').join('-'))
    })
  })

  $('#badge-size-selector').on('change', function(e) {
    const [width, height] = badgeDimensions($(this).val().toLowerCase());
    $('#badge-preview').attr('width', width);
    $('#badge-preview').attr('height', height);
    const sizeSelector = this;
    $('.svg-size').each(function (_, item) {
      $(item).text($(sizeSelector).find('option:selected').val().toLowerCase())
    })
  })
  ////////////////////////////////////////////////////////

  $("#widgetTextCopy").on("click", function (e) {
    e.preventDefault();
    copyToClipboard($(this), $("#widgetText"), true)
  });

  $("#copyReactSnippet").on("click", function (e) {
    e.preventDefault();
    copyToClipboard($(this), $("#snippetText"))
  });

  // we need whitespaces to be removed in case of JS widget but no
  // for react component, so using a flag(trimmedText) to keep record of it
  function copyToClipboard(source_el, target_el, trimmedText = false) {
    var snippetText = target_el.text()

    if (trimmedText) { snippetText = snippetText.replace(/  /g,'').trim()}

    navigator.clipboard.writeText(snippetText);
    const linkTextEl = source_el.find("span");
    linkTextEl.text("Copied!");
    linkTextEl.addClass("copied");
    setTimeout(function () {
      linkTextEl.removeClass("copied");
      linkTextEl.text("copy the script");
    }, 2000);
  }

  $('#verified-badges').on('click', 'img', function () {
    let img = $(this).clone();
    $('#verified-badges-modal').modal('hide');
    img.removeAttr('class')
    img.attr('width', 100);
    img.attr('height', 50);
    $('#svgBadge').text($(img[0]).data('badge-version'));
  });

  if (document.getElementsByClassName('copy-url-wrapper').length) {
    const copyUrlWrapperArray =
      document.getElementsByClassName('copy-url-wrapper');

    for (const copyUrlWrapper of copyUrlWrapperArray) {
      const copyUrlBtn = copyUrlWrapper.querySelector('.copy-url-btn');
      const copyUrlLink = copyUrlWrapper.querySelector('.copy-url-link');
      if (!copyUrlBtn) { continue; }

      copyUrlBtn.addEventListener('click', function () {
        const element = this;

        this.classList.add('copied');
        navigator.clipboard.writeText(copyUrlLink.textContent);

        setTimeout(function () {
          element.classList.remove('copied');
        }, 1000);
      });
    }
  }

  if (document.getElementsByClassName('go-to-section-box').length) {
    const goToBtnArray =
      document.getElementsByClassName('go-to-section-box');
    for (const gotoBtn of goToBtnArray) {
      const targetDivID = gotoBtn.dataset.targetId;

      gotoBtn.addEventListener('click', function (e) {
        if (e.target.tagName.toLowerCase() === 'a') return;

        const navbarHeight = $('.sticky-top .navbar').parent().height();
        $('html, body').animate(
          {
            scrollTop:
              $(targetDivID).offset().top - navbarHeight,
          },
          500
        );
      });
    };
  }

  const collectionBadLinkPath = $('#url-path').attr('collection-badlinks-path');
  ajaxRequest(collectionBadLinkPath, 'GET', { page: 1 })

  $('#refererVerificationModal').modal('show')
});

$(document).on("click", ".malicious-link-share-btn", function (e) {
  const link = $(this).closest('div.copy-url-wrapper')
                      .find('span.copy-url-link').text()
  $('.malicious-link-heading-modal').text(link)
  $('.malicious-link-sharing .copy-url-link').text(link)
})

$(function () {
  if (document.getElementsByClassName('report-scam-input-wrapper').length) {
    const inputWrapperArray = document.getElementsByClassName(
      'report-scam-input-wrapper'
    );

    for (const inputWrapper of inputWrapperArray) {
      const input = inputWrapper.querySelector('.form-control');

      input.addEventListener('focus', function () {
        inputWrapper.classList.add('focused');
      });

      input.addEventListener('blur', function () {
        inputWrapper.classList.remove('focused');
      });

      input.addEventListener('input', function (e) {
        if (e.target.value.trim().length > 0) {
          inputWrapper.classList.add('typed');
        } else {
          inputWrapper.classList.remove('typed');
        }
      });
    }
  }
});

function ajaxRequest(url, type, queryParams  = null, successCallback = null) {
  if (url) {
    $.ajax({
      type: type,
      async: true,
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $("meta[name='csrf-token']").attr("content")
        );
      },
      url: url,
      data: queryParams,
      success: function(result){
        if (typeof successCallback === "function") {
          successCallback(result);
        }
      },
      error: function (jqxhr, status, exception) {
        window.notify("An error occurred!", "error");
      },
    });
  }
}
