
  $(document).on('turbolinks:load', function(){
    $(window).scroll(function () {
        if ($(this).scrollTop() > 50) {
          $('#back-to-top').fadeIn();
        } else {
          $('#back-to-top').fadeOut();
        }
      });
      $('#back-to-top').click(function () {
        $('body,html').animate({
          scrollTop: 0
        }, 800);
        return false;
      });

    // Load ShareJS buttons on article's show page
    if (window.__sharethis__) {
      window.__sharethis__.initialize();
    }
  });