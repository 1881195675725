$(document).on("turbolinks:load", function () {
  const bootstrap = require("bootstrap");

	var navbarHeight = $('.navbar').outerHeight();

  function goTo(target, offsetTop) {
  offsetTop = offsetTop || 0;
  $('html, body').stop().animate({
    'scrollTop': $(target).offset().top - navbarHeight + offsetTop
  });
  }

	// Logic for setting progress-bar percentage in onboarding checklist
	if ($(".dashboard-verify-banner-wrapper").length > 0) {
		let checkList = $('.dashboard-verify-banner-wrapper ul.list-progress');
		let checklistItems = checkList.children();
		let progressCount = checkList.find(`[data-checked=true]`).length;

		if (progressCount < checklistItems.length) {
			$('.checklist-container').show();
		}
	}

	// To display the default remaining count of suggestion links
	if (document.getElementById('suggestion-links-list')) {
		let suggestionLinksCount = $('#suggestion-links-list').children().length;

		// Creating description text based on the remaining suggestion links count.
		let descriptionText = `Confirm if accounts belongs to you (${suggestionLinksCount} remaining)`;
		$('.suggesstion-link-item .description-text').text(descriptionText);
	}

  // if landed on page with some anchor, scroll down
	// to that section

	if ($(location.hash).length > 0) {
    setTimeout(function() {
      goTo(location.hash, -100);
			$(location.hash).find('input.form-control').focus(); // focus the link being edited
    }, 100);
  }


	const copyButton = $('#profile-link-copy-btn');
	var elOriginalText = copyButton.attr('data-bs-original-title');

	copyButton.on('click', function () {
		copyButton.attr('data-bs-original-title', 'Copied').tooltip('show');

		setTimeout(function () {
			copyButton.attr('data-bs-original-title', elOriginalText)
			          .tooltip('show');
		}, 2000);
	});

	const dashboardInfoTooltipArr = [
		...document.getElementsByClassName('dashboard-info-tooltip'),
	];

	if (dashboardInfoTooltipArr.length > 0) {
		dashboardInfoTooltipArr.forEach((dashboardTooltip) => {
			const bootstrapAccordion = dashboardTooltip
				.closest('.accordion-item')
				.querySelector('.accordion-collapse');

			dashboardTooltip.addEventListener('click', function () {
				const bsCollapse = new bootstrap.Collapse(bootstrapAccordion, {
					hide: false,
				});
			});

			return new bootstrap.Tooltip(dashboardTooltip, {
				trigger: 'hover',
				customClass: 'custom-dashboard-info-tooltip',
				fallbackPlacements: ['right', 'left'],
			});
		});
	}

	$('.project-dashboard-content').before($('#flash-messages'))

	// Dashboard post-onboarding welcome animated modal
	if (
		document.getElementsByClassName('welcome-user-animation-modal').length >
		0
	) {
		const modalElement = document.querySelector('#welcomeUserModal');
		const modalInstance = bootstrap.Modal.getOrCreateInstance(modalElement);
		let elementIndex = 1;

		modalInstance.show();

		const animationWrapper =
			modalElement.querySelector('.animation-wrapper');

		modalElement.addEventListener('shown.bs.modal', (e) => {
			animationWrapper.classList.add('first-screen');

			const interVal = setInterval(function () {
				if (elementIndex === 1) {
					animationWrapper.classList.add('second-screen');
					elementIndex++;
					return;
				}
				if (elementIndex === 2) {
					animationWrapper.classList.add('third-screen');
					elementIndex++;
					return;
				}
				if (elementIndex === 3) {
					clearInterval(interVal);

					setTimeout(function () {
						modalInstance.hide();
						elementIndex = 1;
					}, 500);
					location.reload();
					return;
				}
			}, 7500);
			// If we are changing this interval value, we also need to
			// reflect this change in css for transition-animation in (.inner) class in
			// welcome_modal.scss to keep progress-bar animation smooth in Welcome Modal.

		});
	}
})
