const bootstrap = require("bootstrap");

$(document).on("turbolinks:load", function () {
  $(function () {
    var navbarHeight = $(".navbar").outerHeight();
    function goTo(target, offsetTop) {
      offsetTop = offsetTop || 0;
      $("html, body")
        .stop()
        .animate(
          {
            scrollTop: $(target).offset().top - navbarHeight + offsetTop,
          },
          500
        );
    }

    $(".scrollto").click(function (e) {
      e.preventDefault();

      var offsetTop = 0;
      var attr = $(this).attr("data-offset");
      if (typeof attr !== typeof undefined && attr !== false) {
        offsetTop = Number(attr);
      }
      goTo($(this).attr("href"), offsetTop);
    });

    var navigation = document.getElementById("navigation");
    $('[data-toggle="search"]').on("click", function () {
      if (!$(".navbar-search").is(":visible") && navigation) {
        var bsCollapse = new bootstrap.Collapse(navigation, {
          toggle: false,
        });
        bsCollapse.hide();
      }
      $(".navbar-search").slideToggle();
    });
    navigation?.addEventListener("show.bs.collapse", function () {
      if ($(".navbar-search").is(":visible")) {
        $(".navbar-search").hide();
      }
    });

    function toggleSuggestion(el) {
      if (el.val().length > 0) {
        if (!el.next(".suggestion").is(":visible")) {
          el.next(".suggestion").show();
          el.prev(".btn-reset").show();
        }
      } else {
        el.next(".suggestion").hide();
        el.prev(".btn-reset").hide();
      }
    }

    $(".form-search-suggestion").on("keyup focus", function () {
      toggleSuggestion($(this));
    });
    $(".form-search-suggestion").on("blur", function () {
      $(this).next(".suggestion").hide();
    });

    $(".btn-reset").on("click", function () {
      $(this).next(".form-control").val("");
      toggleSuggestion($(this).next(".form-control"));
    });
  });

  if ($(".trending").length) {
    $(".trending__search").on("input", function () {
      if ($(this).val() !== "") {
        $(this).parents(".input-group").addClass("typing");
      } else {
        $(this).parents(".input-group").removeClass("typing");
      }
    });

    $(".trending .clear-form").on("click", function (e) {
      e.preventDefault();
      $(this)
        .parents("form")
        .find(".trending__search")
        .val("")
        .trigger("input");
    });
  }

  if ($(".navbar-home").length > 0) {
    let windowHeight = window.innerHeight;
    let stickyNavHeight = document.querySelector(
      ".navbar.navbar-home"
    ).offsetHeight;
    let spaceAfterStickyNav = windowHeight - stickyNavHeight;
    document.querySelector(".fullscreen-after-sticky-nav").style.minHeight =
      spaceAfterStickyNav + "px";
  }
});
