import { popitup } from '../common_functions'

// Pagination element click behavior for the most_followed pages
$(document).on('turbolinks:load', function () {
    // Add loading spinner to pagination link in lists
    $('.most-followed-page').on('click', '#see-more-link', function(e) {
        e.preventDefault();
        $(this).html('<i class="fa-solid fa-circle-notch fa-spin"></i>'); // Show loading spinner
    });

    // Link to tweet us something
    $('.tweet-link').click(function(e) {
        e.preventDefault();

        var tweetMessage = $(this).data('tweet-message');
        var tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetMessage)}`;
        popitup(tweetUrl);
    });
});