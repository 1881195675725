import { CountUp } from 'countup.js';
import { createAlgoliaSearch } from './algolia-search-initializer';

document.addEventListener('turbolinks:load', () => {
    const percentageElement = $('#percentage');
    const endValue = parseFloat(percentageElement.data('end-value'));

    if (endValue) {
        // Adjust the duration here. The value is in seconds.
        const countUpOptions = {
            duration: 3, // Example: 3 seconds. Adjust this value as needed.
            decimalPlaces: endValue < 1 ? 1 : 0, // Use 1 decimal place for values less than 1, otherwise no decimal places
        };

        const countUp = new CountUp(percentageElement[0], endValue, countUpOptions);
        if (!countUp.error) {
            countUp.start();
        } else {
            console.error(countUp.error);
        }
    }

    // Retrieve these from the global scope or have them passed into this script
    const algoliaAppId = window.algolia_app_id;
    const algoliaPublicKey = window.algolia_public_key;
    const algoliaIndexName = window.aloglia_collection_index_name;

    // Define the hit template for collections
    const collectionHitTemplate = `
    <a href="#" class="algolia-result collection-url d-block" data-slug="{{ slug }}" data-image-url="{{image_url}}" data-name="{{name}}">
        <div class="item-container d-flex align-items-center">
            <div class="image">
                <img src="{{image_url}}" alt="{{name}}" loading="lazy" width="35" height="35" class="me-3">
            </div>
            <div class="text flex-style flex-column align-items-start">
                {{#name}}
                    <h6>{{name}}</h6>
                {{/name}}
                {{#category_names.length}}
                    <div class="subtext">
                        {{#category_names}}
                            <span class="subtext-item">{{.}}</span>
                        {{/category_names}}
                    </div>
                {{/category_names.length}}
            </div>
        </div>
    </a>
  `;

    // Initialize the search for the first collection
    if ($("#first-collection-search-box").length > 0) {
        createAlgoliaSearch(algoliaAppId, algoliaPublicKey, 'first-collection', algoliaIndexName, collectionHitTemplate, (data) => {
            // Set the hidden input value
            $('#first_user_hidden_input').val(data.slug);

            // Update the .first-user-selection div
            $('.first-user-selection .image').html(`<img src="${data.imageUrl}" alt="${data.name}" width="55" height="55" class="rounded-circle me-2" />`);
            $('.first-user-selection .text').text(data.name);
        }, 'Search for a person, brand, etc.', '<q>{{ query }}</q> is not on NotCommon yet...', 'form-control form-control-lg');
    }

    // Initialize the search for the second collection
    if ($("#second-collection-search-box").length > 0) {
        createAlgoliaSearch(algoliaAppId, algoliaPublicKey, 'second-collection', algoliaIndexName, collectionHitTemplate, (data) => {
            // Set the hidden input value
            $('#second_user_hidden_input').val(data.slug);

            // Update the .first-user-selection div
            $('.second-user-selection .image').html(`<img src="${data.imageUrl}" alt="${data.name}" width="55" height="55" class="rounded-circle me-2" />`);
            $('.second-user-selection .text').text(data.name);
        }, 'Search for a person, brand, etc.', '<q>{{ query }}</q> is not on NotCommon yet...', 'form-control form-control-lg');
    }
});