$(function () {
  const customTooltipWrappers = document.querySelectorAll(
    '.custom-tooltip-wrapper'
  );

  if (customTooltipWrappers.length) {
    for (const customTooltipWrapper of customTooltipWrappers) {
      const customTooltipContent = customTooltipWrapper.querySelector(
        '.custom-tooltip-content'
      );
      const dismissBtn =
        customTooltipWrapper.querySelector('.btn-dismiss');

      customTooltipWrapper.addEventListener('mouseenter', function () {
        customTooltipContent.classList.add('show');
      });

      customTooltipWrapper.addEventListener('mouseleave', function () {
        customTooltipContent.classList.remove('show');
      });

      customTooltipWrapper.addEventListener('click', function (e) {
        if (!e.target.closest('.custom-tooltip-content')) {
          customTooltipContent.classList.toggle('show');
        }
      });

      if (dismissBtn) {
        dismissBtn.addEventListener('click', function () {
          customTooltipWrapper.click();
        });
      }
    }
  }
});

$(document).on('turbolinks:load', function () {

  // New navigation menu code
  $('.custom-navbar-toggler').on('click', function (e) {
    e.stopPropagation();
    $('#collapsed-search-box').hide();
    let deviceHeight = $(window).height();
    let navbarHeight = $('.custom-new-navbar').outerHeight(true);
    // menu open
    if (!$(this).hasClass('menu-open')) {
      $('body').css({ overflow: 'hidden' });
      $(this).addClass('menu-open');
      $('.custom-new-navbar').addClass('menu-open-wrapper');
      $('.security-dropdown').slideDown();
      $('.security-menu-link').addClass('open');
      $('.navbar-collapse')
        .css({ overflowY: 'auto' })
        .addClass('opened')
        .animate(
          {
            height: deviceHeight - navbarHeight,
          },
          {
            complete: function () {
              $('.navbar-collapse').addClass('show-content');
            },
          },
          150
        );
    } else {
      $('body').css({ overflow: 'auto' });
      $(this).removeClass('menu-open');
      $('.custom-new-navbar').removeClass('menu-open-wrapper');
      $('.security-dropdown').removeClass('show').slideUp();
      $('.custom-new-navbar .dropdown-menu').each(function () {
        $(this).removeClass('show').slideUp();
      });
      $('.nav-link.dropdown-toggle').each(function () {
        $(this).removeClass('open');
      });
      $('.security-dropdown')
        .parents('.dropdown')
        .find('.dropdown-toggle')
        .removeClass('open');
      $('.navbar-collapse')
        .css({ overflowY: 'hidden' })
        .removeClass('opened')
        .animate(
          {
            height: 0,
          },
          {
            complete: function () {
              $('.navbar-collapse').removeClass('show-content');
            },
          },
          150
        );
    }
  });

  // open submenu on mobile with animation
  $('.nav-link.dropdown-toggle').on('click', function (e) {
    e.stopPropagation();
    const dropdownMenu = $(this)
      .parents('.dropdown')
      .find('.dropdown-menu');
    if ($(window).width() < 992) {
      dropdownMenu.slideToggle();
      $(this).toggleClass('open');
    }
  });

  // show search input when search-icon is clicked
  $('.mobile-search-icon-btn').on('click', function (e) {
    e.stopPropagation();
    $('.nav-search-for-mobile').slideToggle();
  });

  // show submenu on hover, desktop
  $('.nav-link.dropdown-toggle, .dropdown-toggle + .dropdown-menu')
    .on('mouseenter', function () {
      $(this)
        .parents('.dropdown')
        .find('.dropdown-toggle')
        .addClass('show');
      $(this)
        .parents('.dropdown')
        .find('.dropdown-menu')
        .addClass('show');
    })
    .on('mouseleave', function () {
      $(this)
        .parents('.dropdown')
        .find('.dropdown-toggle')
        .removeClass('show');
      $(this)
        .parents('.dropdown')
        .find('.dropdown-menu')
        .removeClass('show');
    });

  // menu behaviour on scroll
  let newNavbarHeight = $('.custom-new-navbar').outerHeight(true);
  let previousScroll;

  $(window).on('scroll', function () {
    let currentScrollTop = $(this).scrollTop();
    if (currentScrollTop > newNavbarHeight) {
      if (currentScrollTop > previousScroll) {
        $('.custom-new-navbar').css({ top: -newNavbarHeight });
        // hide opened sub-menu
        $('.nav-link.dropdown-toggle').each(function () {
          $(this).removeClass('show');
        });
        $('.custom-new-navbar .dropdown-menu').each(function () {
          $(this).removeClass('show');
        });
      } else {
        $('.custom-new-navbar').css({ top: 0 });
      }
    }
    if (currentScrollTop === 0) {
      $('.custom-new-navbar').removeClass('fixed-nav');
    }
    previousScroll = currentScrollTop;
  });

  // resize event
  $(window).on('resize', function () {
    newNavbarHeight = $('.custom-new-navbar').outerHeight(true);
    if ($('.navbar-collapse').hasClass('opened')) {
      $('.navbar-collapse').css({
        height:
          $(window).height() -
          $('.custom-new-navbar').outerHeight(true),
      });
    }

    // adjust menu if mobile menu is resized to desktop one
    if ($(window).width() > 991) {
      $('body').css({ overflowY: 'auto' });
      $('.custom-new-navbar .dropdown-menu').each(function () {
        $(this).removeClass('show').slideUp();
      });
      $('.nav-link.dropdown-toggler').each(function () {
        $(this).removeClass('show');
      });
    }
  });

  // target input to make border black when typed
  if (document.getElementsByClassName('target-input-for-black').length) {
    const inputArray = document.getElementsByClassName(
      'target-input-for-black'
    );

    for (const input of inputArray) {
      input.addEventListener('input', function (e) {
        if (e.target.value.trim().length > 0) {
          input.classList.add('typed');
        } else {
          input.classList.remove('typed');
        }
      });
    }
  }
});
