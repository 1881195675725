import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import { searchBox, hits, configure } from 'instantsearch.js/es/widgets';

// console.log('Algolia search script loaded (OLD)');

// Function to handle pressing enter on a search result
const returnKeyAction = (event, searchInputSelector, searchLinkSelector) => {
    var results_container = $(searchInputSelector).closest('.searchbox').find('.algolia-results-container ol');
    if (event.which == 13) {
        event.preventDefault();
        if ($(results_container).find('li a.active').length > 0) {
            $(results_container).find('li a.active')[0].click();
        } else {
            $(searchLinkSelector)[0].click();
        }
    }
};



// A function to create a search box and hits widget
export const createAlgoliaSearch = (appId, apiKey, containerId, indexName, template, onClick, placeholderText = 'Search...', noResultsText = 'No results for <q>{{ query }}</q>', customInputClasses = '') => {
    const searchClient = algoliasearch(appId, apiKey);

    const search = instantsearch({
        indexName,
        searchClient,
        searchFunction(helper) {
            if (helper.state.query === '') {
                return;
            }
            helper.search();
        },
    });

    const searchWidgets = [
        configure({
            hitsPerPage: 5,
        }),
        // Add the search box widget
        searchBox({
            container: `#${containerId}-search-box`,
            showSubmit: false, // This will hide the submit button
            showReset: false,
            showLoadingIndicator: false,
            placeholder: placeholderText,
            cssClasses: {
                form: 'collection-search', // Adds custom class to the form
                // Adds custom class to the input element
                // TODO: form-control form-control-lg are specific to Bootstrap and should be phased out
                input: customInputClasses, // Use custom classes passed in
                // input: 'form-control form-control-lg notco-search-box',
            },
            // Other configurations...
        }),
        // Add the hits widget
        hits({
            container: `#${containerId}-hits`,
            // cssClasses: {
            //     root: 'my-custom-class', // Adds custom class to the root div of the hits widget
            // },
            templates: {
                item: template,
                empty: noResultsText,
            },
            // Other configurations...
        }),
        // Add other widgets here if necessary
    ];

    // Add the widgets to the search instance
    search.addWidgets(searchWidgets);

    // Start the search
    search.start();

    // Function to hide the hits/results container
    function hideHitsContainer() {
        // console.log('hideHitsContainer');
        $(`#${containerId}-hits`).hide();
    }

    function showHitsContainer() {
        // console.log('showHitsContainer');
        $(`#${containerId}-hits`).show();
    }

    // Event listener to show the hits container when the search input is focused
    const searchInput = document.querySelector(`#${containerId}-search-box .ais-SearchBox-input`);
    searchInput.addEventListener('focus', () => {
        showHitsContainer();
    });

    // Function to handle showing or hiding the hits container based on input content.
    // If input is empty, call hideHitsContainer to hide the search results.
    // Otherwise ensure the results are visible.
    function handleInputVisibility() {
        if (searchInput.value.length === 0) {
            hideHitsContainer();
        } else {
            showHitsContainer();
        }
    }

    // Attach the focus event listener to the search input
    searchInput.addEventListener('focus', handleInputVisibility);
    // Attach the input event listener to the search input
    searchInput.addEventListener('input', handleInputVisibility);

    // Attach the click event listener ONLY AFTER the search renders
    search.on('render', () => {
        const hitsContainer = document.querySelector(`#${containerId}-hits`);
        const searchInput = document.querySelector(`#${containerId}-search-box .ais-SearchBox-input`);

        hitsContainer.addEventListener('click', (event) => {
            event.preventDefault();
            const hit = event.target.closest('.algolia-result');
            if (hit) {
                // Pass the entire dataset object (all the data-attributes of the hit)
                onClick(hit.dataset);
                // Call function to hide the hits container
                hideHitsContainer();
            }
        });

        // Hide the hits container when clicking outside of it
        document.addEventListener('click', (event) => {
            if (!searchInput.contains(event.target) && !hitsContainer.contains(event.target)) {
                hideHitsContainer();
            }
        });

        // Add keypress and keydown event listeners
        $(document).keypress((e) => {
            if ($(e.target).closest(`#${containerId}-search-box`).length) {
                returnKeyAction(e, `#${containerId}-search-box`, '.search-link');
            }
        });

        $(document).keydown((e) => {
            if ($(e.target).closest(`#${containerId}-search-box`).length) {
                moveActiveItem(e, `#${containerId}-search-box`);
            }
        });
    });
};
