$(document).on('turbolinks:load', function() {
  if ($(".auth-form").length) {
    $(".form-control").on("input", function () {
      if ($(this).val() == "") {
        $(this).parents(".input-group").removeClass("active");
      } else {
        $(this).parents(".input-group").addClass("active");
      }
    });

    $(".clear-form").on("click", function (e) {
      e.preventDefault();
      $(this).parents(".auth-form").find(".form-control").val("").trigger("input");
    });

    $(".auth-form").on("submit", function (e) {
      // do not show loader if form is
      // not in valid state
      if ($(this).valid() === false) { return; }

      const loadingHtmlBtn = `
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      `;
      const submitBtn = $(this).find('.btn[type="submit"]');
      submitBtn.attr("disabled", "disabled");
      submitBtn.attr("data-text", submitBtn.text());
      submitBtn.html(loadingHtmlBtn);
    });


    $(".input-group-password").on("click", function (e) {
      var inputGroup = $(this);
      var inputGroupBtn = inputGroup.find(".input-group-btn");
      var passwordField = inputGroup.find(".form-control");

      inputGroupBtn.css("display", "flex");

      passwordField.on("blur", function () {
        inputGroupBtn.css("display", "none");
      });
    });

    $(".toggle-password-visibility").on("click", function (e) {
      e.preventDefault();

      const parent = $(this).parents(".input-group");
      if (parent.hasClass("input-group-password-shown")) {
        parent.removeClass("input-group-password-shown");
        parent.find("input").attr("type", "password");
      } else {
        parent.addClass("input-group-password-shown");
        parent.find("input").attr("type", "text");
      }
    });
  }
});
