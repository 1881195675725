const bootstrap = require("bootstrap");
$(document).on("turbolinks:load", function () {
  $(function () {
    function fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      document.execCommand("copy");
      document.body.removeChild(textArea);
    }

    function copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text);
    }

    $(".wallet-card__copy").on("click", function (e) {
      e.preventDefault();
      const walletCopyText = $(this)
        .parents(".wallet-card")
        .find(".wallet-card__copy__text");
      const copiedText = "Copied!";
      if (walletCopyText.text() != copiedText) {
        copyTextToClipboard($(this).data("copy"));
        const copyTooltip = bootstrap.Tooltip.getInstance(this);
        copyTooltip.hide();
        walletCopyText.attr("text", walletCopyText.text());
        walletCopyText.text(copiedText);
        setTimeout(function () {
          walletCopyText.text(walletCopyText.attr("text"));
        }, 1000);
      }
    });

  });
});
