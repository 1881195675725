const bootstrap = require('bootstrap');
import { popitup } from '../common_functions';
import mixpanel from 'mixpanel-browser';

//Profile

$(document).on('turbolinks:load', function () {
  // Initialize mixpanel
  const mixpanelToken = document.body.dataset.mixpanelProjectKey;
  mixpanel.init(mixpanelToken);

  const userPath = $('#url-path').attr('data-path');
  $('[data-toggle="navigation"]').click(function () {
    $('.navigation').show(0, function () {
      $('body').addClass('navigation-open');
    });
  });
  $('[data-dismiss="navigation"]').click(function () {
    $('body').removeClass('navigation-open');
    setTimeout(function () {
      $('.navigation').hide();
    }, 150)
  });

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip-new"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })

  $('[data-bs-toggle="popover-profile"]').each(function () {
    $(this).popover({
      trigger: 'hover focus',
      container: $(this),
      placement: 'top',
      html: true,
      customClass: 'popover-profile',
      content: $(this).find('.popover-content')
    })
  })
  $('[data-bs-toggle="popover-profile"] a').click(function (e) {
    e.preventDefault();
  })
  $('#dropdownWallet').on('show.bs.dropdown', function () {
    $('[data-toggle="dropdown-wallet"]').addClass('show');
  });
  $('#dropdownWallet').on('hide.bs.dropdown', function () {
    $('[data-toggle="dropdown-wallet"]').removeClass('show');
  });

  $('.profile-sidebar__wallet').click(function(e){
    var wallet = $(this);
    const walletAddress = wallet.data('wallet');
    if(!walletAddress) return;

    wallet.addClass('copied').tooltip('hide');
    navigator.clipboard.writeText(walletAddress);
    setTimeout(function(){
      wallet.removeClass('copied');
    },1000)
  });

  $('.profile-sidebar__wallet + .dropdown .dropdown-item .btn-copy, .profile-sidebar__wallet + .dropdown .dropdown-item .btn-copy-alt').click(function (e) {
    e.stopPropagation();
    var wallet = $(this).parents('.dropdown-item');
    wallet.addClass('copied');
    navigator.clipboard.writeText(wallet.data('wallet'));
    setTimeout(function () {
      wallet.removeClass('copied');
    }, 1000)
  });
  $('[data-toggle="dropdown-wallet"]').click(function (e) {
    e.stopPropagation();
    $('#dropdownWallet').dropdown('toggle');
  });
  $('[data-toggle="dropdown-socialmore"]').click(function (e) {
    e.stopPropagation();
    $('#dropdownSocialMore').dropdown('toggle');
  })
  $('#dropdownSocialMore').on('show.bs.dropdown', function () {
    $('[data-toggle="dropdown-socialmore"]').addClass('show');
  });
  $('#dropdownSocialMore').on('hide.bs.dropdown', function () {
    $('[data-toggle="dropdown-socialmore"]').removeClass('show');
  });


  const stickyElm = document.querySelector('.profile-sidebar-sticky')
  const profileSidebar = document.querySelector('.profile-sidebar')
  var observer = new IntersectionObserver(
    ([e]) => {
      e.target.classList.toggle('is-sticky', e.intersectionRatio > 0 && e.intersectionRatio < 1 && e.boundingClientRect.y < 1);
      //profileSidebar.classList.toggle('hide', e.intersectionRatio > 0 && e.intersectionRatio < 1 && e.boundingClientRect.y < 1);
    }, {
      threshold: [1]
    }
  );
  if (stickyElm !== null) {
    observer.observe(stickyElm);
  }

  $('.btn-about-readmore').click(function (e) {
    e.preventDefault();
    if ($('.text-about').hasClass('more')) {
      $('.text-about').removeClass('more');
      $(this).text('Read more +');
    } else {
      $('.text-about').addClass('more');
      $(this).text('Read less -');
    }
  });
  $('.btn-more-link').click(function () {
    var btnGroup = $('.btn-links-group');
    if (btnGroup.hasClass('more')) {
      btnGroup.removeClass('more');
      $(this).find('span').text('More');
    } else {
      btnGroup.addClass('more');
      $(this).find('span').text('Less');
    }
  });

  $('[data-toggle="collapse-filter"]').click(function () {
    $(this).toggleClass('collapsed');
    $('#collapseFilter').collapse('toggle');
  });

  $('#collapseFilter').on('show.bs.collapse', function() {
    $(this).find('#filter-dropdown').toggleClass('d-none');
  });

  $('#collapseFilter').on('hidden.bs.collapse', function() {
    $(this).find('#filter-dropdown').toggleClass('d-none');
  });

  $('#filterModal').on('hide.bs.dropdown', function () {
    $('[data-toggle="collapse-filter"]').addClass('collapsed');
  });
  $('#filterModal').on('show.bs.dropdown', function () {
    $('[data-toggle="collapse-filter"]').removeClass('collapsed');
  });

  function toggleSuggestionFilter(el) {
    if (el.val().length > 0) {
      if (!el.next('.suggestion-filter').is(':visible')) {
        el.next('.suggestion-filter').show();
        el.prev('.btn-reset-filter').show();
        setTimeout(function () {
          $('.suggestion-filter-result .spinner').hide();
          $('.suggestion-filter-result-list').show();
        }, 1000);
      }
    } else {
      el.next('.suggestion-filter').hide();
      el.prev('.btn-reset-filter').hide();
    }
  }
  $(".form-search-filter").on('keyup focus', function () {
    toggleSuggestionFilter($(this));
  })
  $(".form-search-filter").on('blur', function () {
    $(this).next('.suggestion-filter').hide();
  })

  $('.btn-reset-filter').on('click', function () {
    $(this).next('.form-control').val('');
    toggleSuggestionFilter($(this).next('.form-control'));
  })
  $('.profile-content-viewmore .btn').click(function () {
    $(this).hide();
    $('.profile-content-viewmore p').show();
    $(".nft-grid > .col").clone().appendTo(".nft-grid");
  })

  var toShow = 4,
    slideWidth = 340;
  if ($(window).width() < 992) slideWidth = 280;
  if ($(window).width() > 1700) slideWidth = 350;
  var containerWidth = $('.container').width();
  var avSpace = ($(window).width() - containerWidth) / 2;
  toShow = Math.floor((containerWidth + avSpace) / slideWidth);

  $('[data-toggle="copy-share-link"]').click(function (e) {
    e.preventDefault();
    var thisText = $('#shareAccountModal.modal.fade.modal-share.show').find('.text');
    var sidebarText = $('#sidebar-copy').find('.sidebar-text');
    if (thisText.length > 0) {
      thisText.addClass('fw-bold').text('Copied!');
    } else {
      sidebarText.text('Copied!');
    }
    navigator.clipboard.writeText($(this).attr('href'));
    setTimeout(function () {
      thisText.removeClass('fw-bold').text('Copy link');
      sidebarText.contents().remove();
    }, 1000)
  });

  // When a share on Facebook link is clicked
  $('#facebook_button_id').click(function (e) {
    FB.ui({
        method: 'share',
        href: window.location.href,
      },
      function (response) {}
    );
  });

  // When the Twitter share button is clicked
  $('#twitter_button_id').click(function (e) {
    // Prevent the default action of the click event
    e.preventDefault();

    // Retrieve the tweet text from the data attribute of the button if it exists
    var tweetText = $(this).data('tweetText');

    // Get the base hostname URL from the body's data attribute
    var hostNameURL = $('body').data('hostnameurl');
    // Combine the hostname with the current page path to create the full share URL
    var shareUrl = hostNameURL + window.location.pathname;


    // Construct the Twitter share URL. If tweetText is provided, use the Twitter intent URL to include it.
    // Otherwise, default to just sharing the URL.
    var twitterShareUrl = tweetText ?
        `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}&url=${encodeURIComponent(shareUrl)}&via=verynotcommon` :
        `https://twitter.com/share?url=${encodeURIComponent(shareUrl)}`;

    // Open the Twitter share URL in a popup window
    return popitup(twitterShareUrl);
  });


  //Profile Zero State
  if($('#congratsModal').length>0){
    $('#congratsModal').modal('show');
  }

  $('#profile-nav-links').on('click', 'li', function() {
    $('.zero-state-nav a.active').removeClass('active');
  });

  $('.card-zero-state').on('click',' #get-started-tab', function() {
    $('#profile-nav-links a.active').removeClass('active');
    $('#profile-nav-links #get-started-tab').addClass('active');
  });

  // Remove 'This account is real' banner by clicking 'Dismiss'
  if (document.getElementsByClassName('custom-remove-section-wrapper').length > 0) {
    const wrapperArray = [...document.getElementsByClassName('custom-remove-section-wrapper')];

    const isDismissed= getCookie('popupDismissed') === 'true';

    if (!isDismissed) {
      wrapperArray.forEach((wrapper) => {
        const dismissBtn = wrapper.querySelector('.remove-section-btn');

        dismissBtn.addEventListener('click', () => {
          const wrapperHeight = wrapper.offsetHeight;

          wrapper.style.height = `${wrapperHeight}px`;
          setTimeout(function () {
            wrapper.classList.add('hide-popup');
          }, 100);

          document.cookie = "popupDismissed=true; path=/";
          // Track in Mixpanel dismissing this banner
          // console.log('Real account banner dismissed');
          mixpanel.track("Real Account Banner Dismissed");
        });
      });
    } else {
      wrapperArray.forEach((wrapper) => {
        wrapper.classList.add('hide-popup');
      });
    }
  }

  // Retrieves the value of a specific cookie by its name from the document's cookies
  function getCookie(name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  // Track in Mixpanel expanding a verified accordion on the public profile
  $('.accordion-collapse').on('show.bs.collapse', function() {
    // console.log("Verified Accordion Expanded");
    mixpanel.track("Verified Accordion Expanded");
  });

  // Use Mixpanel's track_links() to track external link clicks on the public profile
  mixpanel.track_links('.name-link-wrapper a', 'Profile Link Click', function(link) {
    // Finding the small tag inside the .name-link-wrapper parent
    const smallTag = link.closest('.name-link-wrapper').querySelector('small');
    const linkType = smallTag ? smallTag.textContent.trim() : 'n/a';

    // Returning properties for the Mixpanel event
    return {
      'link type': linkType
    };
  });
});
