import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import { searchBox, hits, configure } from 'instantsearch.js/es/widgets';

var defaultcollectionName;
document.addEventListener('turbolinks:load', () => {
	// console.log('DOM is ready (OLD)');
	defaultcollectionName = $('#searchbox-collection').attr('data-collection');

	// Initialize main nav search
	if ($('.searchbox-nav-container').length) {
		$('.search-container').each((index, value) => navbarSearch(value))
	}

	// Initialize search for a collection to claim
	if ($('.searchbox-claim-collection-container').length > 0) {
		claimPageSearch();
	}

	// Initialize search for a category to categorize a collection
	if ($('.searchbox-select-category-container').length > 0) {
		categorizeCollectionSearch();
	}

	// Remove a category pill/badge (and the id from the hidden input)
	$('#selected-categories-container').on('click', '.remove-category', function() {
		var categoryId = $(this).closest('.badge').data('category-id');
		removeCategory(categoryId);
		$(this).closest('.badge').remove();
	});

	// Hide the algolia search box when we click off of it
	$('body').unbind('click').click(function (e) {
		if ($('.navbar .results-container').is(':visible') && $(e.target).closest('.navbar').length < 1) {
			hideResultPopUp('.navbar .results-container');
		} else if ($('.home-search .results-container').is(':visible') && $(e.target).closest('.home-search').length < 1) {
			hideResultPopUp('.home-search .results-container');
		} else if ($('.collection-page-search .collection-results').is(':visible') && $(e.target).closest('.collection-page-search').length < 1) {
			hideResultPopUp('.collection-page-search .collection-results');
		} else if ($('.claim-page-search .collection-results').is(':visible') && $(e.target).closest('.claim-page-search').length < 1) {
			hideResultPopUp('.claim-page-search .collection-results');
		} else if ($('.categorize-collection-search .category-results').is(':visible') && $(e.target).closest('.categorize-collection-search').length < 1) {
			hideResultPopUp('.categorize-collection-search .category-results');
		}
	});

	// Handle pressing enter on a search result
	$(document).unbind('keypress').on('keypress', function (e) {
		if ($('.home-search .form-control').is(':focus')) {
			returnKeyAction(e, $('.home-search .form-control'), '.search-home-link');
		} else if ($('.navbar-search .form-control').is(':focus')) {
			returnKeyAction(e, $('.navbar-search .form-control'), '.search-link');
		}
	});

	// Handle using up and down keys to move through results
	$(document).unbind('keydown').on('keydown', function (e) {
		if ($('.navbar-search .form-control').is(':focus')) {
			moveActiveItem('.navbar-search .form-control', e);
		} else if ($('.home-search .form-control').is(':focus')) {
			moveActiveItem('.home-search .form-control', e);
		} else if ($('.collection-page-search .form-control').is(':focus')) {
			moveActiveItem('.collection-page-search .form-control', e);
		} else if ($('.claim-page-search .form-control').is(':focus')) {
			moveActiveItem('.claim-page-search .form-control', e);
		} else if ($('.categorize-collection-search .form-control').is(':focus')) {
			moveActiveItem('.categorize-collection-search .form-control', e);
		}
	})
})

// Handle the "Enter" keypress event (key code 13) within various search input fields in your application.
// It's used to determine what action should be taken when a user presses the Enter key while focusing on
// a search input box.
var returnKeyAction = function (e, search_input, search_link) {
	var results_container = $(search_input).closest('.searchbox').find('.results-container ol');
	if (e.which == 13) {
		e.preventDefault();
		if ($(results_container).find('li a.active').length > 0) {
			$(results_container).find('li a.active')[0].click();
		} else {
			$(search_link)[0].click();
		}
	}
}

// Handle keyboard navigation within search results displayed in a dropdown.
// This function allows users to use the up (keyCode == 38) and down (keyCode == 40)
// arrow keys to navigate through the list of items.
var moveActiveItem = function (item, e) {
	var results_container_arrary = $(item).closest('.searchbox').find('.results-container ol');
	var results_container = results_container_arrary.find('li');
	if (e.keyCode == '38') {
		e.preventDefault();
		if ($(results_container).find('li a:focus').length > 0) {
			$(results_container).find('li a:focus').blur().parent().prev().find('a').addClass('active');
		}
		else if (results_container.find('a.active').length == 0) {
			$(results_container).last().find('a').addClass('active');
		}
		else if ($(results_container).find('a.active')[0] == $(results_container).first().find('a')[0]) {
			$(results_container).first().find('a').removeClass('active')
			$(results_container).last().find('a').addClass('active');
		}
		else {
			var current_item = results_container.find('a.active');
			current_item.removeClass('active');
			var index = results_container.index(current_item.parent());
			$(results_container[index - 1]).find('a').addClass('active');
		}
	}
	else if (e.keyCode == '40') {
		e.preventDefault();
		if ($(results_container).find('li a:focus').length > 0) {
			$(results_container).find('li a:focus').blur().parent().next().find('a').addClass('active');
		}
		else if (results_container.find('a.active').length == 0) {
			$(results_container).first().find('a').addClass('active');
		}
		else if (results_container.find('a.active')[0] == $(results_container).last().find('a')[0]) {
			results_container.find('a.active').removeClass('active')
			$(results_container).first().find('a').addClass('active');
		} else {
			var current_item = results_container.find('a.active');
			current_item.removeClass('active')
			var index = results_container.index(current_item.parent())
			$(results_container[index + 1]).find('a').addClass('active');
		}
	}
}

var hideResultPopUp = function (results_container) {
	$(results_container).hide();
	$('body').removeClass('overflow-hidden');
}

var showResultPopUp = function (val, results_container, search_link) {
	$(results_container).show();
	$('body').addClass('overflow-hidden');
	$(search_link).attr('href', '/search?q=' + val);
}

////////////////////////////////////////////////////////////////
// MAIN NAVBAR SEARCH
////////////////////////////////////////////////////////////////

function navbarSearchProps(searchBoxObject) {
	let searchBoxProps = {
		container: searchBoxObject.querySelector('.searchbox-nav-container'),
		showReset: false,
		placeholder: "Search...",
		showLoadingIndicator: false,
		cssClasses: {
			input: "form-control target-input-for-black",
			submit: 'btn btn-purple',
		},
	}

	let emptyState = "No results for <q>{{ query }}</q>";

	if ($(searchBoxObject).data('src') === 'homepage') {
		// Show submit button in search box on homepage
		searchBoxProps = {...searchBoxProps,
			placeholder: "Look up a person, brand & more...",
			templates: {
			submit() {
				return "Search";
			}
		}}
		emptyState = "<q>{{ query }}</q> is not on NotCommon yet";
	}
	return { searchBoxProps, emptyState };
}

var navbarSearch = function (searchBoxObject) {
	// Search code
	const props = navbarSearchProps(searchBoxObject)
	const results_container = ".navbar-search .results-container";
	const searchClient = algoliasearch(algolia_app_id, algolia_public_key);
	var collection_search = instantsearch({
		indexName: aloglia_collection_index_name,
		searchClient,
	});
	collection_search.addWidgets([
		configure({
			hitsPerPage: 5,
		}),
		searchBox(props.searchBoxProps),
		hits({
			container: searchBoxObject.querySelector("#collection-hits"),
			templates: {
				empty: props.emptyState,
				item: `
						<a href="/{{slug}}" class="collection-url d-block">
							<div class="item-container">
								<div class="image">
									<img src="{{image_url}}" alt="Profile Picture" loading="lazy" onerror="this.onerror=null;">
								</div>
								<div class="text flex-style">
									{{#name}}
										<h6>{{name}}</h6>
									{{/name}}
									{{#category_names.length}}
										<div class="categories">
											{{#category_names}}
                            					<span class="category">{{.}}</span>
                        					{{/category_names}}
										</div>
									{{/category_names.length}}
								</div>
							</div>
						</a>`
			},
		}),
	]);
	collection_search.start();
	collection_search.on("render", () => {
		$("li.ais-Hits-item")
			.unbind("click")
			.on("click", function () {
				hideResultPopUp(results_container);
			});
		$("li.ais-Hits-item")
			.unbind("mouseenter")
			.on("mouseenter", function () {
				$("li.ais-Hits-item").find("a").removeClass("active");
				$(this).find("a").addClass("active");
			});
		$("li.ais-Hits-item")
			.unbind("mouseleave")
			.on("mouseleave", function () {
				$(this).find("a").removeClass("active");
			});
		$(results_container).find("li.ais-Hits-item a").removeClass("active");
	});
	$(".navbar-search .form-control").val(query_param);
	$(".navbar-search .form-control")
		.unbind("focus input")
		.on("focus input", function () {
			var val = $(this).val();
			if (val.length != 0) {
				showResultPopUp(val, results_container, ".search-link");
			} else {
				hideResultPopUp(results_container);
			}
		});
};

////////////////////////////////////////////////////////////////
// COLLECTION SEARCH FOR CLAIMING
////////////////////////////////////////////////////////////////

var claimPageSearch = function () {
  var results_container = ".claim-page-search .results-container";
  const searchClient = algoliasearch(algolia_app_id, algolia_public_key);
  const search_collection = instantsearch({
    indexName: aloglia_collection_index_name,
    searchClient,
  });
  search_collection.addWidgets([
    configure({
      hitsPerPage: 4,
    }),
    searchBox({
      container: ".searchbox-claim-collection-container",
      showSubmit: true,
      showReset: false,
      placeholder: "Search...",
      showLoadingIndicator: false,
      cssClasses: {
        form: "collection-search",
        input: "form-control form-control-lg",
      },
    }),
    hits({
      container: "#claim-collection-hits",
      templates: {
        empty: "No results for <q>{{ query }}</q>",
        item: `
				<a href="/collections/{{ slug }}/claim" class="d-block pt-2">
					<div class="item-container">
						<div class="image">
						<img src="{{image_url}}" alt="Profile Picture" loading="lazy" onerror="this.onerror=null; this.src='/avatar.png'">
						</div>
						<div class="text flex-style">
							{{#name}}
								<h6>{{name}}</h6>
							{{/name}}
						</div>
					</div>
				</a>
          `,
      },
    }),
  ]);
  search_collection.start();
  search_collection.helper.setQuery(defaultcollectionName);
  search_collection.on("render", () => {
    $("li.ais-Hits-item")
      .unbind("click")
      .on("click", function () {
        hideResultPopUp(results_container);
      });
    $("li.ais-Hits-item")
      .unbind("mouseenter")
      .on("mouseenter", function () {
        $("li.ais-Hits-item").find("a").removeClass("active");
        $(this).find("a").addClass("active");
      });
    $("li.ais-Hits-item")
      .unbind("mouseleave")
      .on("mouseleave", function () {
        $(this).find("a").removeClass("active");
      });
    $(results_container).find("li.ais-Hits-item a").removeClass("active");
  });

  $(".collection-search .form-control")
    .unbind("focus input")
    .on("focus input", function () {
      var val = $(this).val();
      if (val.length != 0) {
        showResultPopUp(val, results_container, ".search-home-link");
      } else {
        hideResultPopUp(results_container);
      }
    });
};

////////////////////////////////////////////////////////////////
// CATEGORY SEARCH
////////////////////////////////////////////////////////////////

var categorizeCollectionSearch = function () {
	var results_container = ".categorize-collection-search .results-container";
	const searchClient = algoliasearch(algolia_app_id, algolia_public_key);
	const search_category = instantsearch({
		indexName: aloglia_category_index_name,
		searchClient,
	});
	search_category.addWidgets([
		configure({
			hitsPerPage: 4,
		}),
		// Configure the search box
		searchBox({
			container: ".searchbox-select-category-container",
			showSubmit: false,
			showReset: false,
			placeholder: "Select categories...",
			showLoadingIndicator: false,
			cssClasses: {
				form: "collection-search",
				input: "form-control form-control-lg",
			},
		}),
		// Configure the hits/results
		hits({
			container: "#categorize-collection-hits",
			templates: {
				empty: "No results for <q>{{ query }}</q>",
				item: `
					<a href="#" class="d-block pt-2" data-category-id="{{objectID}}">
						<div class="item-container">
							<div class="text flex-style">
								{{#name}}
								<h6><span>{{name}}</span> ({{collections_count}})</h6>
								{{/name}}
							</div>
						</div>
					</a>
          			`,
			},
		}),
	]);
	search_category.start();
	search_category.on("render", () => {
		// Handle clicking on result
		$("li.ais-Hits-item").unbind("click").on("click", function (e) {
			e.preventDefault();
			var categoryId = $(this).find("a").data('category-id');
			var categoryName = $(this).find("h6 span").first().text().trim();
			// console.log(categoryId);
			// console.log(categoryName);

			if (!isCategoryAlreadySelected(categoryId)) {
				updateSelectedCategories(categoryId);
				appendCategoryPill(categoryId, categoryName);
			}
			hideResultPopUp(results_container);
		});
		// Handle mousing over result
		$("li.ais-Hits-item")
			.unbind("mouseenter")
			.on("mouseenter", function () {
				$("li.ais-Hits-item").find("a").removeClass("active");
				$(this).find("a").addClass("active");
			});
		// Handle mousing away from result
		$("li.ais-Hits-item")
			.unbind("mouseleave")
			.on("mouseleave", function () {
				$(this).find("a").removeClass("active");
			});
		$(results_container).find("li.ais-Hits-item a").removeClass("active");
	});

	$(".collection-search .form-control")
		.unbind("focus input")
		.on("focus input", function () {
			var val = $(this).val();
			if (val.length != 0) {
				showResultPopUp(val, results_container, ".search-home-link");
			} else {
				hideResultPopUp(results_container);
			}
		});
};

// Function to add a hidden field with selected category ID
function updateSelectedCategories(categoryId) {
	var modelType = $('#selected-categories-container').data('model-type');
	var inputName = modelType + '[category_ids][]';  // Constructs the name attribute based on the model

	if (!isCategoryAlreadySelected(categoryId)) {
		$('<input>').attr({
			type: 'hidden',
			id: 'category-id-' + categoryId,
			name: inputName,
			value: categoryId
		}).appendTo($('#selected-categories-container'));
	}
}

// Function to checks if a category has already been selected
function isCategoryAlreadySelected(categoryId) {
	return !!$('#category-id-' + categoryId).length;
}

// Function to create a new Bootstrap pill with the category name and
// append it to the container
function appendCategoryPill(categoryId, categoryName) {
	var pillHtml = `
        <span class="badge bg-dark d-inline-flex align-items-center" data-category-id="${categoryId}">
            ${categoryName}
            <button type="button" class="btn-close btn-close-white remove-category" aria-label="Remove"></button>
        </span>`;
	$('#selected-categories-container').append(pillHtml);
}

// Function to remove the hidden input field corresponding to the category ID
function removeCategory(categoryId) {
	$('#category-id-' + categoryId).remove();
}

////////////////////////////////////////////////////////////////
