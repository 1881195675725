const bootstrap = require('bootstrap');

$(document).on("turbolinks:load", function() {

  var clipboard = new ClipboardJS('.profile-link')

  clipboard.on('success', function (e) {
    window.notify('Address copied to clipboard', 'success');
  });

  $('.btn-open-filter').click(function(){
    $('.sidebar').show(0).addClass('open')
    $('body').addClass('sidebar-open')
  });
  $('.btn-close-filter').click(function(){
    $('.sidebar').removeClass('open')
    setTimeout(function(){
      $('.sidebar').hide(0)
    }, 300)
    $('body').removeClass('sidebar-open')
  });

  $('input:radio[class=banner-check]').click(function() {
    var value = $(this).attr('image_path');
    $('.banner-preview-image').css('background-image',`url(${value})`);
  });

  // Hide Chrome extension upsell banner when the X is clicked
  $('.chrome-extension-upsell-bar button').click(function(){
    $('.chrome-extension-upsell-bar').remove();
  });

});
